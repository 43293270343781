import "../index.scss";
import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { message, Radio, Select, Space } from "antd";
import { GradeEnum } from "../../../types/npsParams";
import { NpsTypeEnum } from "../../../types/npsInfoParams";
import Rating from "./rating";
import { I18n, Translate } from "react-redux-i18n";
import { getCurrentTextTranslation } from "../../../helpers/getCurrentTextTranslation";
import { useActions } from "../../../hooks/useActions";
import RangeSlider from "../../../components/customSlider";

const NpsComponent: FC = () => {
  const { question, npsInfoParams, npsParams } = useSelector((state: RootState) => {
    const question = state.questions?.question;
    const npsInfoParams = question?.answerTemplate?.npsInfoParams;
    return {
      answer: state.questions.answer?.answerValue,
      question: question,
      npsInfoParams: npsInfoParams,
      npsParams: npsInfoParams?.npsParams ?? [],
    };
  });

  const { setUserAnswer } = useActions();
  const [answer, setAnswer] = useState<number | undefined>(undefined);
  const lowestScoreLabel = getCurrentTextTranslation(question?.answerTemplate?.textTranslationsToLowestScoreLabel);
  const highestScoreLabel = getCurrentTextTranslation(question?.answerTemplate?.textTranslationsToHighestScoreLabel);
  const [resetFill, setResetFill] = useState(false);

  const onChange = useCallback(
    (value: number | undefined) => {
      const stringValue = value !== undefined ? String(value) : undefined;
      setAnswer(value);
      setUserAnswer({ answerValue: stringValue, csiAnswers: [] });
    },
    [setUserAnswer],
  );

  const isType = useCallback((type: NpsTypeEnum) => npsInfoParams?.type === type, [npsInfoParams?.type]);

  useEffect(() => {
    setUserAnswer({
      answerValue: undefined,
      csiAnswers: [],
    });
    setAnswer(undefined);
    setResetFill(true);
  }, [question?.questionId]);

  useEffect(() => {
    if (answer === undefined) {
      message.warning(I18n.t("makeChoice")).then();
    }
  }, [answer]);

  return (
    <div className="nps-template">
      <div>
        {npsParams
          ?.sort((it) => (it?.grade === GradeEnum.MAX_GRADE ? 1 : -1))
          ?.map((it) => (
            <div key={it.grade} className="grade-text">
              <span style={{ fontFamily: question?.surveyDecor?.textFont }}>
                <Translate value={it?.grade === GradeEnum.MAX_GRADE ? "max" : "min"} />
              </span>
              <span style={{ fontFamily: question?.surveyDecor?.textFont }}>
                {getCurrentTextTranslation(it?.textTranslations)}
              </span>
            </div>
          ))}
      </div>
      {isType(NpsTypeEnum.SLIDER) ? (
        <>
          <RangeSlider
            min={1}
            max={npsInfoParams?.maxGrade ?? 10}
            value={answer ?? 0}
            step={1}
            onChange={(val: number | undefined) => {
              onChange(val === 0 ? undefined : val);
              setResetFill(false);
            }}
            resetFill={resetFill}
          />
          <div className="lowest-highest-text-container">
            <span className="lowest-text" style={{ fontFamily: question?.surveyDecor?.textFont }}>
              {lowestScoreLabel ?? ""}
            </span>
            <span className="highest-text" style={{ fontFamily: question?.surveyDecor?.textFont }}>
              {highestScoreLabel ?? ""}
            </span>
          </div>
        </>
      ) : isType(NpsTypeEnum.RATING) ? (
        <div className="rating-container">
          {npsInfoParams?.icon && npsInfoParams?.maxGrade && (
            <div style={{ width: "100%" }}>
              <div style={{ marginBottom: 10 }}>
                <Rating
                  onChange={(value) => onChange(value !== undefined ? Number(value) : undefined)}
                  iconType={npsInfoParams.icon}
                  maxValue={npsInfoParams.maxGrade}
                />
              </div>
              <div className="lowest-highest-text-container">
                <span className="lowest-text" style={{ fontFamily: question?.surveyDecor?.textFont }}>
                  {lowestScoreLabel ?? ""}
                </span>
                <span className="highest-text" style={{ fontFamily: question?.surveyDecor?.textFont }}>
                  {highestScoreLabel ?? ""}
                </span>
              </div>
            </div>
          )}
        </div>
      ) : isType(NpsTypeEnum.DROPDOWN) ? (
        <Select
          size="large"
          onChange={(value) => onChange(Number(value))}
          value={answer ? String(answer) : undefined}
          placeholder={<Translate value="select" />}
          style={{ width: "98%", fontFamily: question?.surveyDecor?.textFont }}
        >
          {Array.from(Array(npsInfoParams?.maxGrade).keys())
            .reverse()
            .map((it, index) => (
              <Select.Option value={String(++it)} key={it}>
                {it}
                <span style={{ marginLeft: 15, fontSize: 13 }}>{index === 0 ? highestScoreLabel ?? "" : ""}</span>
                <span style={{ fontSize: 13 }}>
                  {index === (question?.answerTemplate?.npsInfoParams?.maxGrade ?? 0) - 1 ? lowestScoreLabel ?? "" : ""}
                </span>
              </Select.Option>
            ))}
        </Select>
      ) : isType(NpsTypeEnum.RADIO_BUTTONS) ? (
        <Radio.Group value={answer} onChange={({ target }) => onChange(target.value)} size="large">
          <Space direction="vertical">
            {Array.from(Array(npsInfoParams?.maxGrade).keys())
              .reverse()
              .map((it, index) => (
                <Radio value={++it} key={it}>
                  <span style={{ marginLeft: 25, fontFamily: question?.surveyDecor?.textFont }}>{it}</span>
                  <span style={{ marginLeft: 15, fontSize: 13 }}>{index === 0 ? highestScoreLabel ?? "" : ""}</span>
                  <span style={{ fontSize: 13 }}>
                    {index === (question?.answerTemplate?.npsInfoParams?.maxGrade ?? 0) - 1
                      ? lowestScoreLabel ?? ""
                      : ""}
                  </span>
                </Radio>
              ))}
          </Space>
        </Radio.Group>
      ) : null}
    </div>
  );
};

export default NpsComponent;
