import React, { memo, useCallback, useRef } from "react";
import "./index.scss";

type RangeSliderProps = {
  min: number;
  max: number;
  value: number;
  step: number;
  onChange: (newValue: number) => void;
  resetFill?: boolean;
};

const RangeSlider: React.FC<RangeSliderProps> = ({ min = 1, max = 10, value, step = 1, onChange, resetFill }) => {
  const controlsRef = useRef<HTMLDivElement>(null);

  const clampAndStep = useCallback(
    (rawValue: number) => {
      const clampedValue = Math.min(Math.max(rawValue, min), max);
      return Math.round(clampedValue / step) * step;
    },
    [min, max, step],
  );

  const getPercent = useCallback((val: number) => ((val - min) / (max - min)) * 100, [min, max]);

  const onTrackClick = (e: React.MouseEvent) => {
    if (e.target instanceof HTMLElement && e.target.getAttribute("aria-label") === "slider-thumb") {
      return;
    }
    if (!controlsRef.current) return;

    const { left, width } = controlsRef.current.getBoundingClientRect();
    let newPercent = (e.clientX - left) / width;
    newPercent = Math.min(Math.max(newPercent, 0), 1);

    const rawValue = min + newPercent * (max - min);
    const newValue = clampAndStep(rawValue);

    if (newValue !== value) {
      onChange(newValue);
    }
  };

  const fillWidth = resetFill ? "0%" : `${getPercent(value)}%`;

  const totalMarks = max - min + 1;
  const marks = Array.from({ length: totalMarks }, (_, i) => min + i);

  console.log(value, marks);
  return (
    <div className="range-slider" aria-label="range-slider-component">
      {value !== 0 && (
        <div className="range-slider__value-banner" style={{ left: fillWidth }}>
          {value}
        </div>
      )}

      <div className="range-slider__controls" ref={controlsRef} aria-label="slider-controls" onClick={onTrackClick}>
        <div className="range-slider__track" aria-label="slider-track" aria-hidden="true" />
        <div className="range-slider__fill" aria-label="slider-fill" style={{ width: fillWidth }} />

        {marks.map((mark) => {
          if (mark > max) return null;

          const markPercent = getPercent(mark);
          const isActive = mark < value;
          const isSelected = mark === value;

          return (
            <div
              key={mark}
              className={`range-slider__mark ${isActive ? "range-slider__mark--active" : ""} ${
                isSelected ? "range-slider__mark--selected" : ""
              }`}
              style={{ left: `${markPercent}%` }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default memo(RangeSlider);
